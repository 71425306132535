import { MathJax } from 'better-react-mathjax';
import React, { useEffect, useState } from 'react'
import { formatText } from '../../utils/formatText';
import { Isummary } from '../../models/quizModel';
import clsx from "clsx";
import styles from "../../styles/QuizSlider.module.scss";
import './quiz.css'
import RepeatedMath from '../../components/RepeatedMath';
interface props {
    summary: Isummary[]
}
const SummeryComponent = ({ summary }: props) => {
    const [correctCount, setCorrectCount] = useState<number>(0)
    const [wrongCount, setWrongCount] = useState<number>(0)
    const [answeredCount, setAnsweredCount] = useState<number>(0)

    useEffect(() => {
        window.scrollTo(0, 0)
        summary?.forEach((summ) => {
            if (summ?.user_answer_flag?.length !== 0) {
                const allValuesTrue = summ?.user_answer_flag?.every((bool) => bool === true);

                if (allValuesTrue) {

                    setCorrectCount((prevCorrectCount) => prevCorrectCount + 1);
                } else {
                    setWrongCount((prevWrongCount) => prevWrongCount + 1);
                }
            } else {
                setWrongCount((prevWrongCount) => prevWrongCount + 1);
            }
            summ?.user_answer_flag?.forEach((bool) => {
                if (bool === true || bool === false) {
                    setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary]);

    return (
        <>
            <div className="d-flex">
                <div className="d-flex align-items-center">
                    <span className={clsx(styles.span, "bg-success me-2")} />
                    <span className="fw-bold fs-6">
                        {/* {correctCount} */}
                        Correct Answer ( {correctCount})
                    </span>
                </div>
                <div className="d-flex align-items-center">
                    <span className={clsx(styles.span, "bg-danger ms-3 me-2")} />
                    <span className="fw-bold fs-6">
                        {/* {wrongCount} */}
                        Wrong Answer ( {answeredCount - correctCount})
                    </span>
                </div>
                <div className="d-flex align-items-center">
                    <span className={clsx(styles.span, "bg-warning ms-3 me-2")} />
                    <span className="fw-bold fs-7">Your Answers ({answeredCount})</span>
                </div>
                <div className="d-flex align-items-center">
                    <span className={clsx(styles.span, "bg-info ms-3 me-2")} />
                    <span className="fw-bold fs-7">Total Questions ({summary.length})</span>
                </div>
            </div>
            {
                summary?.map((summ: Isummary, idx: number) => (
                    <div key={idx} className="bg-light shadow-lg quiz-wrapper mb-3">
                        <div className="">
                            {/* <div className="quiz-header">
                                <div className="fw-bold">
                                    <span className="pr-four">
                                        {" "}
                                        <i className="fa-solid fa-square-check"></i>{" "}
                                    </span>
                                    {summ.question_type === 'mcq' ? "Multiple choice question" : summ.question_type === 'checkbox' ? "Multiple answer question" : summ.question_type === 'true/false' ? "True or False" : summ.question_type === 'text' ? "Text" : ""}
                                </div>
                                <div className="d-flex gap-3">
                  <div className="border-grey rounded-3 p-2 fw-bold">
                    <span className="pr-four">
                      <i className="fa-regular fa-clock"></i>
                    </span>
                    60 seconds
                  </div>
                  <div className="border-grey rounded-3 p-2 fw-bold">
                    <span className="pr-four">
                      <i className="fa-regular fa-clock"></i>
                    </span>
                    1 point
                  </div>
                </div>
                            </div> */}
                            <div className="row pt-3  ">
                                <div className="d-flex gap-1" style={{
                                    width: "100%",
                                    overflowX: "hidden"
                                }}>
                                    <div className='d-flex align-items-top '>
                                        <div
                                            className='rounded-circle d-flex justify-content-center align-items-center  fw-semibold  '
                                            style={{
                                                height: "30px",
                                                width: "30px",

                                                color: "black",
                                            }}>
                                            {idx + 1}{"."}
                                        </div>
                                    </div>
                                    <RepeatedMath answerText={summ?.question_text} />
                                    {/* <p
                                        className="fs-5 question-text"
                                        dangerouslySetInnerHTML={{
                                            __html: formatText(summ?.question_text),
                                        }}
                                    /> */}


                                </div>
                                <div className="answers pt-3" style={{
                                    width: "100%",
                                    overflowX: "hidden"
                                }}>
                                    {/* <p className="line">
                                        <span className="p-60">Answer Choices</span>
                                    </p> */}
                                    <ul className="d-flex justify-content-between flex-wrap pt-1">
                                        {summ.qn_answers.map((ans, idx) => {
                                            return (

                                                <li key={idx}>
                                                    <div className="col-md-12">
                                                        <div className={`d-flex align-items-baseline rounded-2 px-1 ${summ?.user_answer?.includes(ans?.id) && 'bg-warning'}`}>
                                                            <div className={ans?.is_correct ? "bg-correct" : "bg-incorrect"}></div>
                                                            <label key={ans.id} htmlFor={`${ans.id}`}>
                                                                <div style={{ width: "100%", overflow: "hidden" }}>

                                                                    <div className='px-2'>
                                                                        {/* <p className="p-2 mx-2" style={{ color: "", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: formatText(ans.answer_text) }} /> */}
                                                                        <RepeatedMath answerText={ans?.answer_text} />

                                                                    </div>

                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>

                                            );
                                        })}
                                    </ul>
                                </div>
                                {/* <div className="answers pt-2 " style={{
                                    width: "100%",
                                    overflowX: "hidden"
                                }}>



                                    <div className={`p-2 answer-result  rounded-3   ${summ.user_answer_flag.length > 0 ? summ?.user_answer_flag.length > 1 ? summ.user_answer_flag[0] === true ? "bg-success" : "bg-danger" : summ?.user_answer_flag?.map((bool) => (
                                        bool === true ? "correct" : "incorrect"

                                    )) : 'incorrect'}`}>
                                        <p className="">
                                            <span className="p-1 fw-bold ">Your Answer:</span>
                                        </p>
                                        {
                                            summ.user_answer.length < 1 ? <p className="" style={{ fontSize: "15px" }}>Answer Not given</p> : null
                                        }
                                        {
                                            summ.user_answer.map((an, idx) => (
                                                <div key={idx} className="m-0 px-2">
                                                    <RepeatedMath answerText={an} />

                                                </div>

                                            ))
                                        }

                                    </div>

                                </div> */}
                            </div>
                            <hr />
                            <div className="answers pt-1">
                                <p className="">
                                    <span className="fw-semibold ">Answer Explanation:</span>
                                </p>
                                {summ.reason ? (
                                    <div style={{ width: "100%", overflow: "hidden" }}>
                                        <RepeatedMath answerText={summ?.reason} />
                                        {/* <p
                                            className="pt-1 answer-result"
                                            dangerouslySetInnerHTML={{
                                                __html: formatText(summ?.reason),
                                            }}
                                        /> */}

                                    </div>
                                ) : (
                                    <p className="pt-3">No Explanation found</p>
                                )}
                            </div>
                        </div>

                    </div>
                ))
            }
        </>
    )
}

export default SummeryComponent