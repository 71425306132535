import React, { useState } from "react"
import { formatText } from "../../utils/formatText"
import incrementAlphabeticalLetters from "../../utils/incrementAlphabeticalLetters"

import styles from "../../styles/Quiz.module.scss"
import clsx from "clsx"
import { MathJax } from "better-react-mathjax"
import RepeatedMath from "../../components/RepeatedMath"
interface Props {
  idx: number
  question: any
  aadQnList: (question_id: number, answer_id: number, type: string, quiz_id: number) => void
  qnList: any
  setQnList: any
}
const SingleQuestion: React.FC<Props> = ({
  idx,
  question,
  aadQnList,
  qnList,
  setQnList,
}) => {
  const [selected, setSelected] = useState<number>()

  return (
    <>
      <div className='row question-card ' key={idx} >
        <div>
          <div className='d-flex gap-2   mt-3  mb-1'>
            <div className='d-flex align-items-top '>
              <div
                className='  bg-success   rounded-circle d-flex justify-content-center align-items-center  fw-semibold  '
                style={{
                  height: "30px",
                  width: "30px",
                  border: "1px solid black",
                  color: "white",
                }}>
                {idx + 1}{" "}
              </div>
            </div>
            <div className='d-flex align-items-top ' style={{
              width: "100%",
              overflowX: "hidden"
            }}>
              {/* <p
                className='dangerous-text m-0 p-0'
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                }}
                dangerouslySetInnerHTML={{
                  __html: formatText(question?.question_text),
                }}
              /> */}
              <RepeatedMath answerText={question?.question_text} />

            </div>
          </div>

          <div className='mt-2 mt-lg-8'>
            {question?.question_type === "mcq" &&
              <div className='radio-inputs'>
                {
                  question?.qn_answers.map((e: any, idx: any) => (

                    <label className="d-flex " key={e.id}
                      style={{
                        maxWidth: "100%",
                      }}
                    >
                      <input
                        type='radio'
                        name={question.id?.toString() || ""}

                        className="radio-input"
                        onChange={() => {
                          aadQnList(question.id!, e.id, question.question_type!, question?.quiz_id)
                        }}
                      />
                      <span className="radio-tile">
                        <span className="radio-label overflow-hidden ">
                          <span className="number">
                            {incrementAlphabeticalLetters(idx)}
                          </span>

                          {/* <p
                            dangerouslySetInnerHTML={{
                              __html: formatText(e?.answer_text),
                            }}
                          /> */}

                          <RepeatedMath answerText={e?.answer_text} />


                        </span>

                      </span>
                    </label>

                  ))
                }

              </div>
            }
            {question?.question_type === "checkbox" &&
              question?.qn_answers.map((e: any, idx: any) => (
                <div
                  key={e.id}
                  className=' overflow-hidden p-0 m-0 d-flex align-items-center'>
                  <input
                    autoComplete='off'
                    type='checkbox'
                    name={question.id?.toString() || ""}
                    id={e.id.toString()}
                    onChange={async (event) => {
                      if (event.target.checked) {
                        aadQnList(question.id!, e.id, question.question_type!, question?.quiz_id)
                        // setSelected([...selected, e.id]);
                      }
                      if (event.target.checked === false) {
                        //remove the answer from the answer_id array
                        const qnListCopy = [...qnList]
                        const index = qnListCopy.findIndex(
                          (i) => i.question_id === question.id
                        )
                        qnListCopy[index].answer_id = qnListCopy[
                          index
                        ].answer_id.filter((i: any) => i !== e.id)
                        setQnList(qnListCopy)
                        // selected.splice(selected.indexOf(e.id), 1);
                      }
                    }}
                  />
                  <label
                    htmlFor={e.id.toString()}
                    className={`${clsx(styles.quizOption)} ${selected === e.id && "bg-quiz"
                      }`}
                    style={{
                      height: "100% !important",
                      margin: "0px !important",
                      padding: "0px !important",
                    }}>

                    <RepeatedMath answerText={e?.answer_text} />

                  </label>
                </div>
              ))}
            {question?.question_type === "text" && (
              <div className='m-2'>
                <textarea
                  placeholder='Start writing the answer...'
                  className='form-control'
                  rows={5}
                  onChange={(e) => {
                    // handleDisableSubmit(false);
                  }}
                />
              </div>
            )}
            {question?.question_type === "true/false" &&
              question?.qn_answers.map((e: any, idx: number) => (
                <div
                  key={idx}
                  className={clsx(styles.quizOption)}
                  onClick={() => {
                    // setSelected(e.answer_text);
                    // handleDisableSubmit(false);
                  }}>
                  <span className={clsx(styles.quizOptionNumber)}>
                    {incrementAlphabeticalLetters(idx)}
                  </span>

                  <RepeatedMath answerText={e?.answer_text} />

                </div>
              ))}
          </div>
        </div>

        {question?.image_url && (
          <div style={{ width: "300px", height: "300px" }}>
            <img
              alt='quiz-img'
              src={question?.image_url}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default SingleQuestion