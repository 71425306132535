import React, { FC, useEffect } from "react";

interface Props {
  remainingTime: number;
  handleSetRemainingTime: () => void;
}
const Timer: FC<Props> = ({ remainingTime, handleSetRemainingTime }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSetRemainingTime();
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="d-flex justify-content-end">
      <div className="p-1 border rounded" style={{ background: "#f8f8f8" }}>
        <div
          className="m-0 d-flex gap-2 align-items-center"
          style={{ fontSize: "12px" }}
        >
          Time left:
          <span
            className="text-white bg-black p-1 border rounded d-block"
            style={{ width: "23px", height: "23px" }}
          >
            {remainingTime.toString().padStart(2, "0")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Timer;
