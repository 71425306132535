import { useParams } from "react-router-dom";
import QuizCourse from "../subjects/QuizCourse";
import { useQuery } from "@tanstack/react-query";
import LoadingPlaceholder from "../courses/LoadingPlaceholder";

const API_URL =
  process.env.REACT_APP_API_URL || "https://teamnepal-dev.bidhee.net/api";

const AskedBankQuiz = () => {
  const { slug } = useParams<{ slug: string }>();
  const course = useParams<{ id: string }>();

  // We'll add the set sequence here to ensure consistency with QuizCourse
  const generateSetSequence = () => {
    const sets = [];
    for (let i = 65; i <= 90; i++) {
      // ASCII values: A=65, Z=90
      sets.push(`Set ${String.fromCharCode(i)}`);
    }
    return sets;
  };

  // Generate the same sequence available in QuizCourse
  const setSequence = generateSetSequence();

  const getAskedBankQuestions = async () => {
    const token = localStorage.getItem("asp-authv2");
    let headers: HeadersInit = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${JSON.parse(token!)?.access}`;
    }

    try {
      const response = await fetch(
        `${API_URL}/course/${slug}/asked-bank-quiz/?limit=80`,
        { method: "GET", headers }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching asked bank questions:", error);
      throw error;
    }
  };

  const getAskedBankQuestion = useQuery({
    queryKey: ["questionSet", course.id],
    queryFn: getAskedBankQuestions, // Direct function call
    staleTime: Infinity,
  });

  const refetchQuestionSet = () => {
    getAskedBankQuestion.refetch();
  };

  return (
    <>
      {/* Loading Placeholder */}
      <div>
        {(getAskedBankQuestion.isLoading ||
          getAskedBankQuestion.isFetching) && <LoadingPlaceholder />}
      </div>

      <QuizCourse
        refetchQuestionSet={refetchQuestionSet}
        slug={course.id}
        name={getAskedBankQuestion?.data?.[0]?.name || ""}
        QeustionSet={getAskedBankQuestion?.data?.[0]?.quiz_questions || []}
      />
    </>
  );
};

export default AskedBankQuiz;
