import { MathJax } from 'better-react-mathjax';
import React, { useEffect, useState } from 'react';
import { formatText } from '../utils/formatText';
import Latex from 'react-latex';

const RepeatedMath = ({ answerText }: {
    answerText: string;
}) => {
    // const [modifiedAnswerText, setModifiedAnswerText] = useState('');

    // useEffect(() => {
    //     // Check if answerText is not undefined or null
    //     if (answerText) {
    //         // Create a temporary container element to parse the HTML
    //         const tempContainer = document.createElement('div');
    //         tempContainer.innerHTML = answerText;

    //         // Find and remove the elements with class 'katex-html' or any other class you want to remove
    //         const elementsToRemove = tempContainer.getElementsByClassName('katex-html');
    //         for (let i = 0; i < elementsToRemove.length; i++) {
    //             const element = elementsToRemove[i];
    //             element?.parentNode?.removeChild(element);
    //         }

    //         // Retrieve the modified HTML without the specified elements
    //         const modifiedContent = tempContainer.innerHTML;

    //         // Set the modified HTML in the state
    //         setModifiedAnswerText(modifiedContent);
    //     }
    // }, [answerText]);

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: formatText(answerText) }} style={{
                fontSize: "16px !important ",
            }}></div>
            {/* <Latex>{answerText}</Latex> */}

        </>
    );
};

export default RepeatedMath;
