
import Slider, { Settings } from "react-slick";

import SVG from "react-inlinesvg";
import NewQuiz from "./NewQuiz";
import { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Timer from "../../components/sliders/QuizSlider/Timer";
import { useNavigate, useParams } from "react-router-dom";
interface qnList {
    question_id: number
    answer_id: number[] | []
    quiz_id: number

}

interface Props {

    name: string;
    quiz_questions: any;
    handleSubmit: () => void;
    setQnList: React.Dispatch<React.SetStateAction<qnList[]>>
    handelFineshQuiz: () => void
    qnList: qnList[];
}
const NewQuizSlider = ({ name, quiz_questions, handleSubmit, setQnList, handelFineshQuiz, qnList }: Props) => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()

    localStorage.removeItem("qnList")
    //cp is current progress
    const [cp, setCp] = useState<number>(0)
    const sliderRef = useRef<any>();

    const [remainingTime, setRemainingTime] = useState<number>(60)

    //get quiz by subjectid using react query this will provide some amount of question usally 10 if possible

    //function to add question id and answer id to qnList
    function aadQnList(question_id: number, answer_id: number, type: string, quiz_id: number) {
        if (type === "mcq") {
            //check if question_id is already in qnList
            const qnListCopy = [...qnList]
            const index = qnListCopy.findIndex((e) => e.question_id === question_id)
            //if not in qnList, add it
            if (index === -1) {
                qnListCopy.push({ question_id, answer_id: [answer_id], quiz_id: quiz_id! })
            }
            //if in qnList, update it
            else {
                qnListCopy[index].answer_id = [answer_id]
            }
            setQnList(qnListCopy)
        }
        if (type === "true/false") {
            //check if question_id is already in qnList
            const qnListCopy = [...qnList]
            const index = qnListCopy.findIndex((e) => e.question_id === question_id)
            //if not in qnList, add it
            if (index === -1) {
                qnListCopy.push({ question_id, answer_id: [answer_id], quiz_id: quiz_id! })
            }
            //if in qnList, update it
            else {
                qnListCopy[index].answer_id = [answer_id]
            }
            setQnList(qnListCopy)
        }
        if (type === "checkbox") {
            //it will accept multiple answers
            //check if question_id is already in qnList
            const qnListCopy = [...qnList]
            const index = qnListCopy.findIndex((e) => e.question_id === question_id)
            //if not in qnList, add it
            if (index === -1) {
                //push it as a answer array
                qnListCopy.push({ question_id, answer_id: [answer_id], quiz_id: quiz_id! })
            }
            //if in qnList, update it
            else {
                //add new answer to the answer_id array
                qnListCopy[index].answer_id = [
                    ...qnListCopy[index]?.answer_id,
                    answer_id,
                ]
            }
            setQnList(qnListCopy)
        }

    }
    //setting for slider
    const settings: Settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        swipe: false,
        adaptiveHeight: true,
        draggable: false,
    };
    const next = () => {
        sliderRef?.current?.slickNext();
        if (cp <= quiz_questions?.length) {
            setCp(cp + 1);
        }

    };
    const skip = () => {
        sliderRef?.current?.slickNext();
        setCp(cp + 1);
    }
    useEffect(() => {

        if (quiz_questions.length === cp) {
            handleSubmit()
            setCp(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cp])

    // Function to go to next slide and reset time to 60 seconds

    useEffect(() => {
        //eslint-disable-next-line
        const intervalId = setInterval(() => {
            setRemainingTime(remainingTime - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [remainingTime]);

    useEffect(() => {
        if (remainingTime === 0) {
            handleNext()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingTime])

    const handleNext = () => {
        // Clear the previous timer
        next();
        setRemainingTime(60);
    };
    const handleSkip = () => {
        skip();
        setRemainingTime(60);
    }
    //submit quiz This will submit all question user have done at the same time

    const handleSetRemainingTime = () => {
        setRemainingTime((prevTime) => prevTime - 1);
    }
    return (
        <>
            {

                <>
                    <div>
                        <h4>{name}</h4>
                    </div>
                    <ProgressBar
                        className="mb-3"
                        now={cp}
                        style={{ height: "2px" }}
                        min={0}
                        max={quiz_questions?.length}
                    />
                    <div className="d-flex justify-content-end ">
                        <Timer
                            remainingTime={remainingTime}
                            handleSetRemainingTime={handleSetRemainingTime}
                        />
                    </div>
                    <Slider ref={sliderRef} {...settings} >
                        {
                            quiz_questions.map((quiz: any, index: number) => (
                                <NewQuiz key={index} QuestionList={quiz} aadQnList={aadQnList} nextQuestion={handleNext} />
                            ))
                        }
                    </Slider>
                    <div className="d-flex mt-4">
                        <button

                            onClick={handleNext}
                            className="btn btn-primary d-flex align-items-center me-3"
                        // disabled={disableSubmit ? true : false}
                        >

                            Next
                        </button>
                        {/* <button
                            onClick={handleSkip}
                            className="btn me-3 d-flex align-items-center buttons-quiz"
                            style={{ color: "#0483f9", borderColor: "#0483f9" }}
                        >
                            <SVG
                                src="/media/icons/skip.svg"
                                className="me-2 svg-button"
                            />
                            <span>Skip</span>
                        </button> */}
                        <button
                            className="btn me-3 d-flex align-items-center buttons-quiz"
                            style={{ color: "#198754", borderColor: "#198754" }}
                            onClick={handelFineshQuiz}
                        >
                            <SVG
                                src="/media/icons/check-circle-fill.svg"
                                className="me-2"
                            />
                            <span>Submit</span>
                        </button>
                        <button
                            onClick={
                                () => navigate(-1)
                            }
                            className="btn btn-outline-danger d-flex align-items-center"
                        >
                            <SVG
                                src="/media/icons/x-circle.svg"
                                className="me-2"
                            />
                            <span>Quit Quiz</span>
                        </button>
                    </div>

                </>
            }

        </>
    )
}

export default NewQuizSlider