import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";

// API Functions
import {
  getRandomQuizByChapter,
  submitRandomChapterQuizList,
} from "../../api/Quiz";

// Models
import { Isummary } from "../../models/quizModel";

// Components
import SummeryComponent from "./summery";
import RandomTestSlider from "./RandomTestSlider";

// Interfaces
interface qnList {
  question_id: number;
  answer_id: number[] | [];
  quiz_id: number;
}

interface QuizQuestion {
  id: number;
  question_text: string;
  question_type: string;
  qn_answers: {
    id: number;
    answer_text: string;
    is_correct: boolean;
    explanation?: string;
  }[];
  quiz_id: number;
}

const RandomChapterQuiz: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [qnList, setQnList] = useState<qnList[]>([]);
  const [finishQuiz, setFinishQuiz] = useState<boolean>(false);
  const [summary, setSummary] = useState<Isummary[]>();

  // Fetch Quiz Questions
  const ChapterQuiz = useQuery<{
    data: {
      id: number;
      name: string;
      quiz_questions: QuizQuestion[];
    }[];
  }>({
    queryKey: ["ChapterQuiz", id],
    queryFn: () => getRandomQuizByChapter(id!),
    retry: 3,
    staleTime: Infinity,
  });

  // Initial fetch and check for empty quiz
  useEffect(() => {
    ChapterQuiz.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ChapterQuiz.data?.data[0].quiz_questions.length === 0) {
      handleFinishQuiz();
    }
  }, [ChapterQuiz.data]);

  // Submit Quiz Mutation
  const submitQuiz = useMutation({
    mutationKey: ["submitQuiz"],
    mutationFn: (qnList: qnList[]) => submitRandomChapterQuizList(qnList),
    onSuccess: (res) => {
      // Local storage management for summary
      if (localStorage.getItem("summary") === null) {
        localStorage.setItem("summary", JSON.stringify(res?.data));
      } else {
        const oldlist = localStorage.getItem("summary");
        const oldlistParsed = JSON.parse(oldlist!);
        const newList = [...oldlistParsed, ...res?.data];
        localStorage.setItem("summary", JSON.stringify(newList));
      }

      if (finishQuiz) {
        setSummary(
          localStorage.getItem("summary") &&
            JSON.parse(localStorage.getItem("summary")!)
        );
      } else {
        ChapterQuiz.refetch();
      }
      setQnList([]);
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });

  // Handle Quiz Finish
  const handleFinishQuiz = () => {
    setFinishQuiz(true);
    submitQuiz.mutate(qnList);
  };

  // Handle Submit
  const handleSubmit = () => {
    if (qnList.length !== 0) {
      submitQuiz.mutate(qnList);
    }
  };

  // Clear summary from local storage when summary is set
  useEffect(() => {
    localStorage.removeItem("summary");
  }, [summary]);

  // Continue Quiz
  const handleContinue = () => {
    setFinishQuiz(false);
    setSummary(undefined);
    localStorage.removeItem("summary");
    ChapterQuiz.refetch();
  };

  // Render Summary
  if (summary) {
    return (
      <>
        <SummeryComponent summary={summary} />
        <div className="d-flex justify-content-center mt-4 gap-4">
          <button className="btn btn-success" onClick={handleContinue}>
            Continue with quizzes
          </button>
          <button
            onClick={() => window.history.back()}
            className="btn btn-primary d-flex align-items-center"
          >
            <SVG src="/media/icons/check-circle-fill.svg" className="me-2" />
            Finish quiz
          </button>
        </div>
      </>
    );
  }

  // Loading State
  if (
    ChapterQuiz.status === "loading" ||
    submitQuiz.isLoading ||
    ChapterQuiz.isFetching
  ) {
    return (
      <div className="card-body mt-5">
        <span className="placeholder col-12 mt-3"></span>
        <div className="d-flex justify-content-end ">
          <span className="placeholder col-3 mb-3 mt-3"></span>
        </div>

        <p className="card-text placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder col-12"></span>
          <span className="placeholder col-12"></span>
          <span className="placeholder col-12"></span>
          <span className="placeholder col-12"></span>
          <span className="placeholder col-12"></span>
        </p>
        <div className="mt-5 d-flex gap-4">
          {[1, 2, 3, 4].map((_, index) => (
            <button
              key={index}
              className="btn btn-primary disabled placeholder"
              aria-disabled="true"
              style={{ width: "75px" }}
            ></button>
          ))}
        </div>
      </div>
    );
  }

  // No Quiz Available
  if (!summary && ChapterQuiz?.data?.data[0].quiz_questions.length === 0) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <h3>
          Congratulations! You have completed all quizzes for this Chapter
        </h3>
      </div>
    );
  }

  // Quiz Slider
  return (
    <RandomTestSlider
      handleSubmit={handleSubmit}
      quiz_questions={ChapterQuiz?.data?.data[0].quiz_questions}
      name={ChapterQuiz?.data?.data[0].name}
      setQnList={setQnList}
      qnList={qnList}
      handelFineshQuiz={handleFinishQuiz}
    />
  );
};

export default RandomChapterQuiz;
