import axios from "./apiclient";

interface qnList {
  question_id: number;
  answer_id: number[] | [];
  quiz_id: number;
}

export const getAskedBankQuestions = (courseId: string) => {
  const token = localStorage.getItem("asp-authv2");
  let config;
  if (token) {
    config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token!)?.access}`,
      },
    };
  } else {
    config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/course/${courseId}/asked-bank-quiz/?limit=80`,
    config
  );
  return res;
};

export const getCourseQuiz = (courseId: string) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/course/${courseId}/subject/practice_quizs/`,
    config
  );
  return res;
};
export const getCourseQuizTrial = (courseId: string) => {
  // header configration

  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/subject/${courseId}/sample/practice_quiz`
  );
  return res;
};
// submit quiz
export const submitQuizList = (qnList: qnList[]) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.post(
    `${process.env.REACT_APP_API_URL}/quiz/answer/submit/`,
    [...qnList],
    config
  );
  return res;
};
export const submitSubjectQuizList = (qnList: qnList[]) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.post(
    `${process.env.REACT_APP_API_URL}/quiz/subject/answer/submit/`,
    [...qnList],
    config
  );
  return res;
};
//submit chapter quiz
export const submitChapterQuizList = (qnList: qnList[]) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.post(
    `${process.env.REACT_APP_API_URL}/quiz/chapter/answer/submit/`,
    [...qnList],
    config
  );
  return res;
};

export const submitRandomChapterQuizList = (qnList: qnList[]) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.post(
    `${process.env.REACT_APP_API_URL}/quiz/chapter/answer/submit/`,
    [...qnList],
    config
  );
  return res;
};

export const getQuizBySubject = (slug: string) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };
  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/changedsubject/${slug}/practice_quiz/`,
    config
  );
  return res;
};
export const getQuizBySubjectTrail = (slug: string) => {
  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/subject/${slug}/sample/practice_quiz/?limit=30`
  );
  return res;
};

//get quiz by chapter
export const getQuizByChapter = (slug: string) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/changedchapter/${slug}/practice_quiz/?limit=20`,
    config
  );
  return res;
};

//get random test by chapter
export const getRandomQuizByChapter = (slug: string) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/chapter/${slug}/learn_quiz/?limit=50`,
    config
  );
  return res;
};

export const LearnQuizByChapter = (slug: string) => {
  // header configration
  const token = localStorage.getItem("asp-authv2");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token!)?.access}`,
    },
  };

  const res = axios.get(
    `${process.env.REACT_APP_API_URL}/chapter/${slug}/learn_quiz/?limit=20`,
    config
  );
  return res;
};
