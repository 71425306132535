export const formatText = (text: string) => {
  if(!text)
  {
    return ""
  }
  else{
    return text.replace(/<br\s*\/?>/g, "")
    .replace(/<p>\s*<\/p>/g, "")
    .replace(/<span>\s*<\/span>/g, "")
    .replace(/<p[^>]*>(&nbsp;|\s)*<\/p>/g, "")
    .replace(/<br[^>]*>/g, "")
    .replace(/<p><br><\/p>/g, '')
    .replace(/<p>(<br\s*\/?>|\s*)<\/p>/g, "") 
    .replace(/<p><span[^>]*>(&nbsp;|\s)*<\/span><\/p>/g, "")
    .replace(/<p [^>]*><span [^>]*>&nbsp;<\/span><\/p>/g, "") 
  }
  
  
}
