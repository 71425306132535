

const LoadingPlaceholder = () => {
    return (
        <div>
            <div className="card" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>
            <div className="card mt-2" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>
            <div className="card" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>
            <div className="card" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>
            <div className="card" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>
            <div className="card" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>
            <div className="card" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>
            <div className="card" aria-hidden="true">

                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-9"></span>
                        <span className="placeholder col-5"></span>
                        <span className="placeholder col-8"></span>
                    </p>

                </div>
            </div>

        </div>
    )
}

export default LoadingPlaceholder