import { FC, useEffect, useState } from "react";
import { Isummary, QuizQuestion } from "../../models/quizModel";
import SingleQuestion from "./SingleQuestion";
import SVG from "react-inlinesvg";
import { useMutation } from "@tanstack/react-query";
import { submitQuizList } from "../../api/Quiz";
import { toast } from "react-toastify";
import SummeryComponent from "./summery";

interface Props {
  QeustionSet: QuizQuestion[];
  name: string;
  slug?: string;
  refetchQuestionSet: () => void;
}
interface qnList {
  question_id: number;
  answer_id: number[] | [];
  quiz_id: number;
}

const QuizCourse: FC<Props> = ({
  QeustionSet,
  name,
  slug,
  refetchQuestionSet,
}) => {
  const [section1, setSection1] = useState<QuizQuestion[]>([]);
  const [section2, setSection2] = useState<QuizQuestion[]>([]);
  const [qnList, setQnList] = useState<qnList[]>([]);
  const [finishQuiz, setFinishQuiz] = useState<boolean>(false);
  const [summary, setSummary] = useState<Isummary[]>();

  // Generate dynamic set sequence from A to Z
  const generateSetSequence = () => {
    const sets = [];
    for (let i = 65; i <= 90; i++) {
      // ASCII values: A=65, Z=90
      sets.push(`Set ${String.fromCharCode(i)}`);
    }
    return sets;
  };

  // Dynamic set sequence A-Z
  const setSequence = generateSetSequence();

  // Track the current set
  const [currentSet, setCurrentSet] = useState<string>(setSequence[0]);
  // Track current set index
  const [currentSetIndex, setCurrentSetIndex] = useState<number>(0);

  const SUbmitQuizMutation = useMutation({
    mutationKey: ["submitQuiz"],
    mutationFn: (qnList: qnList[]) => submitQuizList(qnList),
    onSuccess: (data) => {
      setQnList([]);
      setSummary(data?.data);
    },
    onError: (error: any) => {
      // toast.error(error.response.data.detail)

      if (error.response.data) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    setQnList(
      QeustionSet.map((e) => {
        return { question_id: e.id!, answer_id: [], quiz_id: e.quiz_id! };
      })
    );
  }, [QeustionSet]);

  function aadQnList(
    question_id: number,
    answer_id: number,
    type: string,
    quiz_id: number
  ) {
    if (type === "mcq") {
      //check if question_id is already in qnList
      const qnListCopy = [...qnList];
      const index = qnListCopy.findIndex((e) => e.question_id === question_id);
      //if not in qnList, add it
      if (index === -1) {
        qnListCopy.push({
          question_id,
          answer_id: [answer_id],
          quiz_id: quiz_id!,
        });
      }
      //if in qnList, update it
      else {
        qnListCopy[index].answer_id = [answer_id];
      }
      setQnList(qnListCopy);
    }
    if (type === "true/false") {
      //check if question_id is already in qnList
      const qnListCopy = [...qnList];
      const index = qnListCopy.findIndex((e) => e.question_id === question_id);
      //if not in qnList, add it
      if (index === -1) {
        qnListCopy.push({
          question_id,
          answer_id: [answer_id],
          quiz_id: quiz_id!,
        });
      }
      //if in qnList, update it
      else {
        qnListCopy[index].answer_id = [answer_id];
      }
      setQnList(qnListCopy);
    }
    if (type === "checkbox") {
      //it will accept multiple answers
      //check if question_id is already in qnList
      const qnListCopy = [...qnList];
      const index = qnListCopy.findIndex((e) => e.question_id === question_id);
      //if not in qnList, add it
      if (index === -1) {
        //push it as a answer array
        qnListCopy.push({
          question_id,
          answer_id: [answer_id],
          quiz_id: quiz_id!,
        });
      }
      //if in qnList, update it
      else {
        //add new answer to the answer_id array
        qnListCopy[index].answer_id = [
          ...qnListCopy[index]?.answer_id,
          answer_id,
        ];
      }
      setQnList(qnListCopy);
    }
  }

  //
  const HandleFinishQuiz = async () => {
    setFinishQuiz(true);
    await SUbmitQuizMutation.mutate(qnList);
  };

  //handel continue
  const handleContinue = async () => {
    window.scrollTo(0, 0);
    if (!summary) {
      //In handel continue, question list will submit frist this is because to prevent repetition of questions in new set
      await SUbmitQuizMutation.mutate(qnList);
    } else {
      // Update to the next set when continuing
      const nextSetIndex = (currentSetIndex + 1) % setSequence.length;
      setCurrentSetIndex(nextSetIndex);
      setCurrentSet(setSequence[nextSetIndex]);

      // Reset states for the new set
      setSummary(undefined);
      setSection1([]);
      setSection2([]);
      setFinishQuiz(false);

      // Instead of reloading the page, call refetchQuestionSet to get new questions
      refetchQuestionSet();
    }
  };

  useEffect(() => {
    if (QeustionSet?.length > 0) {
      // Clear previous sections when loading new questions
      setSection1([]);
      setSection2([]);

      QeustionSet.map((e) => {
        if (e.section === "Section 1") {
          setSection1((prev) => [...prev, e]);
        }
        if (e.section === "Section 2") {
          setSection2((prev) => [...prev, e]);
        }
      });
    }
  }, [QeustionSet]);

  if (summary) {
    return (
      <>
        <SummeryComponent summary={summary} />
        <div className="d-flex justify-content-center mt-4 gap-4">
          <button className="btn btn-success" onClick={handleContinue}>
            Continue with quiz (
            {setSequence[(currentSetIndex + 1) % setSequence.length]})
          </button>
          <a
            href={`/courses/${slug}`}
            className="btn btn-primary d-flex align-items-center"
          >
            <SVG src="/media/icons/check-circle-fill.svg" className="me-2" />
            Finish quiz
          </a>
        </div>
      </>
    );
  }

  return (
    <>
      <h5>
        {name} - {currentSet}
      </h5>
      {SUbmitQuizMutation.isLoading && (
        <div
          className="d-flex justify-content-center align-items-center position-fixed "
          style={{ inset: "0px", backdropFilter: "blur(10px)", zIndex: "50" }}
        >
          <div className="spinner-border text-black  " role="status">
            <span className="visually-hidden">Submitting...</span>
          </div>
        </div>
      )}
      <div className="my-5">
        <h5>Section 1</h5>
      </div>
      {section1?.map((question, idx) => {
        return (
          <SingleQuestion
            key={idx}
            question={question}
            aadQnList={aadQnList}
            qnList={qnList}
            idx={idx}
            setQnList={setQnList}
          />
        );
      })}
      {section2?.length > 0 && (
        <>
          <div className="my-5">
            <h5>Section 2</h5>
          </div>
          {section2?.map((question, idx) => {
            return (
              <SingleQuestion
                key={idx}
                question={question}
                aadQnList={aadQnList}
                qnList={qnList}
                idx={idx}
                setQnList={setQnList}
              />
            );
          })}
        </>
      )}

      <div className="d-flex  justify-content-end mt-5">
        <div className="d-flex gap-5 ">
          <button className="btn btn-primary" onClick={HandleFinishQuiz}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default QuizCourse;

interface answers {
  is_correct: boolean;
  id: number;
  answer_text: string;
}
