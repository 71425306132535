import React, { useState, useEffect } from "react";

interface QuizQuestion {
  id: number;
  question_text: string;
  qn_answers: {
    id: number;
    answer_text: string;
    is_correct: boolean;
    explanation?: string;
  }[];
  quiz_id: number;
}

interface qnList {
  question_id: number;
  answer_id: number[] | [];
  quiz_id: number;
}

interface NewQuizSliderProps {
  quiz_questions?: QuizQuestion[];
  name?: string;
  setQnList: React.Dispatch<React.SetStateAction<qnList[]>>;
  qnList: qnList[];
  handleSubmit: () => void;
  handelFineshQuiz: () => void;
}

const RandomTestSlider: React.FC<NewQuizSliderProps> = ({
  quiz_questions = [],
  name,
  setQnList,
  qnList,
  handelFineshQuiz,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setSelectedAnswer(null);
    setShowPreview(false);
  }, [currentQuestionIndex]);

  const handleAnswerSelect = (answerId: number) => {
    setSelectedAnswer(answerId);
  };

  const handlePreviewAnswer = () => {
    setShowPreview(true);
  };

  const handleNextQuestion = () => {
    // If an answer is selected, add to qnList
    if (selectedAnswer !== null) {
      const currentQuestion = quiz_questions[currentQuestionIndex];
      const newQnListItem = {
        question_id: currentQuestion.id,
        answer_id: [selectedAnswer],
        quiz_id: currentQuestion.quiz_id,
      };

      setQnList((prev) => [...prev, newQnListItem]);
    }

    // Move to next question or finish quiz
    if (currentQuestionIndex + 1 < quiz_questions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      handelFineshQuiz();
    }
  };

  if (!quiz_questions.length) return null;

  const currentQuestion = quiz_questions[currentQuestionIndex];
  const stripHtmlTags = (html: string) => {
    return html
      .replace(/<[^>]*>/g, "") // Remove HTML tags
      .replace(/&nbsp;/g, " ") // Replace non-breaking spaces with regular spaces
      .replace(/&[^;]+;/g, "") // Remove other HTML entities
      .trim();
  };

  // Find the correct answer
  const correctAnswer = currentQuestion.qn_answers.find(
    (answer) => answer.is_correct
  );

  return (
    <div className="container">
      <div className="card mb-4">
        <div className="card-header bg-primary text-white">{name}</div>
        <div className="card-body">
          <div className="mb-3">
            <p
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: currentQuestion.question_text,
              }}
            />
          </div>

          <div className="list-group">
            {currentQuestion.qn_answers.map((answer) => (
              <button
                key={answer.id}
                className={`list-group-item list-group-item-action ${
                  selectedAnswer === answer.id ? "active" : ""
                } ${
                  showPreview
                    ? answer.is_correct
                      ? "list-group-item-success"
                      : selectedAnswer === answer.id
                      ? "list-group-item-danger"
                      : ""
                    : ""
                }`}
                onClick={() => handleAnswerSelect(answer.id)}
              >
                {stripHtmlTags(answer.answer_text)}
              </button>
            ))}
          </div>

          {/* Explanation Section - Always shown when preview is clicked */}
          {showPreview && (
            <div className="alert alert-info mt-3">
              <strong>Explanation:</strong>
              <p>
                {correctAnswer && correctAnswer.explanation
                  ? correctAnswer.explanation
                  : "No specific explanation is available for this question."}
              </p>
            </div>
          )}

          <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-success" onClick={handlePreviewAnswer}>
              Preview answer
            </button>

            <button className="btn btn-primary" onClick={handleNextQuestion}>
              {currentQuestionIndex + 1 === quiz_questions.length
                ? "Finish"
                : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomTestSlider;
