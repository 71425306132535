import { useState, FC } from "react";
import clsx from "clsx";
import styles from "../../styles/Quiz.module.scss";
import incrementAlphabeticalLetters from "../../utils/incrementAlphabeticalLetters";
import { QnAnswer, QuizQuestion } from "../../models/quizModel";

import RepeatedMath from "../../components/RepeatedMath";
interface Props {
  QuestionList: QuizQuestion;
  nextQuestion: () => void;
  aadQnList: (
    question_id: number,
    answer_id: number,
    type: string,
    quiz_id: number
  ) => void;
}

const NewQuiz: FC<Props> = ({ QuestionList, aadQnList, nextQuestion }) => {
  const [textAnswer, setTextAnswer] = useState<string | undefined>(undefined);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [remaningTime, setRemaningTime] = useState<number>(60);
  let data = QuestionList?.qn_answers;
  let chosenAnswers: QnAnswer[] = [];
  const handleCheckbox = (value: QnAnswer) => {
    const idx = chosenAnswers.indexOf(value);
    if (idx === -1) chosenAnswers.push(value);
    else chosenAnswers = chosenAnswers.filter((e) => e !== value);
  };

  const handleQuestionList = () => {
    if (chosenAnswers && chosenAnswers?.length) {
      return {
        question: QuestionList?.id,
        answer: chosenAnswers.map((e) => e.id),
        // answer_text: "This field may not be blank.",
      };
    } else if (textAnswer) {
      return {
        question: QuestionList?.id,
        // answer: [],
        answer_text: textAnswer,
      };
    }
  };

  return (
    <div className=" overflow-hidden ">
      <div className="d-flex justify-content-end "></div>
      <div className="d-flex gap-3">
        <div
          style={{
            height: "30px",
            width: "30px",
            aspectRatio: "1/1",
            borderRadius: "50%",
            backgroundColor: "#0D6EFD",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          #
        </div>

        {/* <div
                    className="dangerous-text"
                    dangerouslySetInnerHTML={{
                        __html: formatText(QuestionList?.question_text),
                    }}
                /> */}
        <RepeatedMath answerText={QuestionList?.question_text} />
      </div>

      <div className="mt-2 mb-5 my-lg-8">
        {QuestionList?.question_type === "mcq" &&
          QuestionList?.qn_answers?.map((e, idx) => (
            <div
              key={e.id}
              className={clsx(
                styles.quizOption,
                selectedIndex === e.id && "bg-secondary text-white"
              )}
              onClick={() => {
                setSelectedIndex(e.id);
                aadQnList(
                  QuestionList?.id!,
                  e.id!,
                  QuestionList?.question_type!,
                  QuestionList?.quiz_id!
                );
              }}
            >
              <span className={clsx(styles.quizOptionNumber)}>
                {incrementAlphabeticalLetters(idx)}
              </span>
              {/* <p dangerouslySetInnerHTML={{ __html: formatText(e.answer_text) }}></p> */}
              <RepeatedMath answerText={e?.answer_text} />
            </div>
          ))}

        {QuestionList?.question_type === "checkbox" &&
          data?.map((e, idx) => (
            <label key={e.id} htmlFor={`${e.id}`} className="d-block">
              <div
                className={clsx(
                  styles.quizOption,
                  "d-flex",
                  "align-items-center"
                )}
              >
                <input
                  className="form-check-input m-0 me-2"
                  type="checkbox"
                  id={`${e.id}`}
                  onChange={({ target }) => handleCheckbox(e)}
                />
                <p className="m-0">{e.answer_text}</p>
              </div>
            </label>
          ))}
      </div>

      <button className="d-none" />
    </div>
  );
};

export default NewQuiz;
