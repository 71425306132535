import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { getQuizBySubject, submitSubjectQuizList } from "../../api/Quiz";
import { toast } from "react-toastify";
import { Isummary } from "../../models/quizModel";
import SummeryComponent from "./summery";
import NewQuizSlider from "./NewQuizSlider";
import { useEffect, useState } from "react";
import SVG from "react-inlinesvg";

interface qnList {
  question_id: number;
  answer_id: number[] | [];
  quiz_id: number;

  // }
  // interface answers {
  //     is_correct: boolean,
  //     id: number,
  //     answer_text: string,
  //     quiz_id: number,
}
const RandomTest = ({ isTrail }: { isTrail?: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const [qnList, setQnList] = useState<qnList[]>([]);
  const [finishQuiz, setFinishQuiz] = useState<boolean>(false);
  const [summary, setSummary] = useState<Isummary[]>();
  const [numberOfQuestion, setNumberOfQuestion] = useState<number>(0);

  //get quiz by subjectid using react query this will provide some amount of question usally 10 if possible
  const subjectQuiz = useQuery({
    queryKey: ["subjectQuiz", id],
    queryFn: () => getQuizBySubject(id!),
    onSuccess: (res) => {
      setNumberOfQuestion(res.data[0].quiz_questions.length);
    },
    retry: 3,
    staleTime: Infinity,
  });
  useEffect(() => {
    subjectQuiz.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitQuiz = useMutation({
    mutationKey: ["submitQuiz"],
    mutationFn: (qnList: qnList[]) => submitSubjectQuizList(qnList),
    onSuccess: (res) => {
      // after completing a set of question it will be submited and summery will be saved in local storage
      //if summery is already in local storage it will be added to in the local storage
      //this will continue till user finish the quiz
      if (localStorage.getItem("summary") === null) {
        localStorage.setItem("summary", JSON.stringify(res?.data));
      } else {
        const oldlist = localStorage.getItem("summary");
        const oldlistParsed = JSON.parse(oldlist!);
        const newList = [...oldlistParsed, ...res?.data];
        localStorage.setItem("summary", JSON.stringify(newList));
      }
      if (finishQuiz) {
        setSummary(
          localStorage.getItem("summary") &&
            JSON.parse(localStorage.getItem("summary")!)
        );
      } else {
        subjectQuiz.refetch();
      }
      setQnList([]);
    },
    onError: (err) => {
      toast.error("Something went wrong");
    },
  });

  const handelFineshQuiz = () => {
    setFinishQuiz(true);
    submitQuiz.mutate(qnList);
  };

  const handleSubmit = () => {
    if (qnList.length !== 0) {
      submitQuiz.mutate(qnList);
    }
  };

  useEffect(() => {
    localStorage.removeItem("summary");
  }, [summary]);
  const handelContinue = () => {
    setFinishQuiz(false);
    setSummary(undefined);
    localStorage.removeItem("summary");
    subjectQuiz.refetch();
  };

  if (summary) {
    return (
      <>
        <SummeryComponent summary={summary} />
        <div className="d-flex justify-content-center mt-4 gap-4">
          <button className="btn btn-success" onClick={handelContinue}>
            Continue with quiz
          </button>
          <Link
            to={`/subjects/${id}`}
            className="btn btn-primary d-flex align-items-center"
          >
            <SVG src="/media/icons/check-circle-fill.svg" className="me-2" />
            Finish quiz
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      {subjectQuiz.isLoading ||
      submitQuiz.isLoading ||
      subjectQuiz.isFetching ? (
        <div className="card-body mt-5">
          <span className="placeholder col-12 mt-3"></span>
          <div className="d-flex justify-content-end ">
            <span className="placeholder col-3 mb-3 mt-3"></span>
          </div>

          <p className="card-text placeholder-glow d-flex flex-column gap-4">
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
          </p>
          <div className="mt-5 d-flex gap-4">
            <button
              className="btn btn-primary disabled placeholder"
              aria-disabled="true"
              style={{
                width: "75px",
              }}
            ></button>
            <button
              className="btn btn-primary disabled placeholder"
              aria-disabled="true"
              style={{
                width: "75px",
              }}
            ></button>
            <button
              className="btn btn-primary disabled placeholder "
              aria-disabled="true"
              style={{
                width: "75px",
              }}
            ></button>
            <button
              className="btn btn-primary disabled placeholder "
              aria-disabled="true"
              style={{
                width: "75px",
              }}
            ></button>
          </div>
        </div>
      ) : numberOfQuestion === 0 ? (
        <div
          className="d-flex flex-column gap-4 justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <h3>Congratulation You have complete All quiz of this Subject</h3>
          <Link
            replace
            to={`/subjects`}
            className="btn btn-primary d-flex align-items-center"
          >
            <SVG src="/media/icons/arrow-back.svg" className="me-2" />
            Go Back
          </Link>
        </div>
      ) : (
        <>
          <NewQuizSlider
            handleSubmit={handleSubmit}
            quiz_questions={subjectQuiz?.data?.data[0].quiz_questions}
            name={subjectQuiz?.data?.data[0].name}
            setQnList={setQnList}
            qnList={qnList}
            handelFineshQuiz={handelFineshQuiz}
          />
        </>
      )}
    </>
  );
};

export default RandomTest;
